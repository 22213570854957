import React from "react";
import '../Stylesheets/header/header.css';

import '../Stylesheets/layout/centre.css';
import '../Stylesheets/centre/centrestyles.css';
export default function BlueLine() {
    return (
        <div className="middle-element">
            <div className="blueline">
            </div>
        </div>
    )
}

